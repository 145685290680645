import React from 'react';

import Text from '../../../../components/text';
import { useClassnames } from '../../../../hooks/use-classnames';
import Arrow from '../../../../images/arrow-white-bg.inline.svg';

import './index.css';

import { Link } from 'gatsby';


interface IProps {
    title: string,
    background: string,
    text: string,
    link: string
}

const CareerImageWithLink: React.FC<IProps> = ({ title, background, text, link }) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-image-with-link')}>
            <div
                className={cn('career-image-with-link__wrapper')} style={{
                    backgroundImage: `url(${background})`
                }}
            >
                <h3 className={cn('career-image-with-link__title')} dangerouslySetInnerHTML={{ __html: title }} />
                <Link className={cn('career-image-with-link__link')} to={link}>
                    <Arrow className={cn('career-image-with-link__arrow')} />
                </Link>
            </div>
        </section>
    );
};

export default CareerImageWithLink;

import React from 'react';

import './index.css';

import { useClassnames } from '@/hooks/use-classnames';
import { ITitleAndText } from '@/types/strapi/career';

const CareerFeature: React.FC<ITitleAndText> = ({ title, text }) => {
    const cn = useClassnames();

    return (
        <div className={cn('career-feature')}>
            <div className={cn('career-feature__title')} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={cn('career-feature__text')} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default CareerFeature;

import React from 'react';
import 'swiper/swiper.min.css';

import Footer from '../footer';
import Layout from '../layout';
import PageSection from '../PageSection';

import CareerAdditions from './components/CareerAdditions';
import CareerBanner from './components/CareerBanner';
import CareerConnect from './components/CareerConnect';
import CareerDirections from './components/CareerDirections';
import CareerFeatures from './components/CareerFeatures';
import CareerFullscreenSlider from './components/CareerFullscreenSlider';
import CareerGeography from './components/CareerGeography';
import CareerImageWithLink from './components/CareerImageWithLink';
import CareerNumbers from './components/CareerNumbers';
import CareerTasks from './components/CareerTasks';
import CareerVacancies from './components/CareerVacancies';
import CareerVideo from './components/CareerVideo';
import CareerWork from './components/CareerWork';
import CareerWorth from './components/CareerWorth';
import {
    numbers,
    features,
    tasks,
    worth,
    slides,
    dataSlider,
    connectList,
    additions
} from './mocks/index';

import './index.css';

import { IGlobalServerRuntimeData } from '@/types/strapi/global';
import { IPostType, TBlogPost } from '@/types/strapi/posts';
import { IDirection, IVacancy, TVacanciesHot } from '@/types/strapi/vacancies';

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    directions: Array<IDirection>,
    vacancies: Array<IVacancy>,
    vacanciesHot: Array<TVacanciesHot>,
    posts: Array<TBlogPost>,
    postTypes: Array<IPostType>
}

interface IProps {
    data: IServerRuntimeData
}

const seo = {
    metaTitle      : 'Карьера',
    metaDescription: '-'
};

const CareerPage: React.FC<IProps> = ({ data }) => {
    return (
        <Layout
            seo={seo}
            hasContactButton={true}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
        >
            {/* <PageSection*/}
            {/*    className="career-page__section career-page__heading"*/}
            {/*    removeAboveOffset={true}*/}
            {/*    removeBelowOffset={true}*/}
            {/* >*/}
            {/*    <GatsbyLink className="career-page__heading-back" to="/">*/}
            {/*        <Back className="career-page__heading-back-arrow" />*/}
            {/*        <div className="career-page__heading-back-text">*/}
            {/*            На главную*/}
            {/*        </div>*/}
            {/*    </GatsbyLink>*/}
            {/*    <NightThemeButton />*/}
            {/* </PageSection>*/}
            <PageSection
                className="career-page__section career-page__banner"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerBanner
                    title="Вместе мы создаём универсальную технологию автономного вождения и продукты на&nbsp;основе искусственного интеллекта"
                    buttonText="Наши вакансии"
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__numbers"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerNumbers data={numbers} />
            </PageSection>
            <PageSection
                className="career-page__section career-page__video"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerVideo source="https://autotech.team/static/hr-video-2c9d53e980343407c1a85d80ea2aea0a.mp4" />
            </PageSection>
            <PageSection
                className="career-page__section career-page__worth"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerWorth
                    title="Работать в&nbsp;Navio&nbsp;&mdash; значит"
                    data={worth}
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__tasks"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerTasks
                    title="Мы развиваем инновационные<br/>быстрорастущие продукты"
                    data={tasks}
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__numbers-info"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerFeatures data={features} />
            </PageSection>
            <PageSection
                className="career-page__section career-page__geography"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerGeography />
            </PageSection>
            <PageSection
                className="career-page__section career-page__directions"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerDirections
                    directions={data.directions}
                    vacancies={data.vacancies}
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__additions"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerAdditions data={additions} />
            </PageSection>
            <PageSection
                className="career-page__section career-page__fullscreen-slider"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerFullscreenSlider
                    data={slides}
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__work"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerWork
                    keyI={2}
                    data={dataSlider}
                    subtitle="Работать над продуктом, который меняет будущее, &mdash;&nbsp;значит постоянно изучать что-то новое. Мы любим эксперименты и&nbsp;стремимся к&nbsp;постоянному развитию. У&nbsp;нас есть все ресурсы, чтобы каждый мог раскрыть свой потенциал."
                    title="Открываем возможности для профессионального и&nbsp;личностного роста"
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__connect"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerConnect
                    title="Как <span class='career-connect__title_accent'>присоединиться</span> к&nbsp;команде"
                    list={connectList}
                />
            </PageSection>
            <PageSection
                className="career-page__section career-page__hot-vacancies"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerVacancies title="Горящие вакансии" data={data.vacanciesHot} />
            </PageSection>
            {/* <PageSection*/}
            {/*    className="career-page__media"*/}
            {/*    removeAboveOffset={true}*/}
            {/*    removeBelowOffset={true}*/}
            {/* >*/}
            {/*    <CareerMedia*/}
            {/*        title="Медиа"*/}
            {/*        posts={data.posts}*/}
            {/*        postTypes={data.postTypes}*/}
            {/*    />*/}
            {/* </PageSection>*/}
            <PageSection
                className="career-page__section career-page__make-feature"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <CareerImageWithLink
                    title="Мы создаём будущее каждый день.<br /> Присоединяйтесь"
                    text="Смотреть вакансии"
                    background="/career/bg.jpg"
                    link="/vacancies"
                />
            </PageSection>
            {/* <PageSection
                className="career-page__section career-page__vacancies-form"
                removeAboveOffset={true}
                removeBelowOffset={true}
            >
                <VacanciesForm className="career-page__vacancies-form-item" />
            </PageSection> */}
            <Footer className="career-page__footer" data={data.footer} />
        </Layout>
    );
};

export default CareerPage;

import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './index.css';

import { useClassnames } from '@/hooks/use-classnames';
import Arrow from '@/images/slider-arrow.inline.svg';

const swiperSetting = {
    navigation: {
        prevEl: '.career-screen-slider__nav-el_prev',
        nextEl: '.career-screen-slider__nav-el_next'
    }
};

interface IProps {
    data: Array<string>
}

const CareerScreenSldier: React.FC<IProps> = ({ data }) => {
    const cn = useClassnames();

    return (
        <div className="career-screen-slider">
            <div className="career-screen-slider__wrapper">
                <Swiper
                    className={cn('career-screen-slider__slider')}
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={0}
                    modules={[Navigation]}
                    {...swiperSetting}
                    loop={true}
                >
                    {data.map((item, index) => (
                        <SwiperSlide
                            key={index}
                            className={cn('career-screen-slider__slide')}
                        >
                            <img
                                className={cn(
                                    'career-screen-slider__slide-item'
                                )}
                                src={item}
                            />
                        </SwiperSlide>
                    ))}
                    <div className={cn('career-screen-slider__slider-nav')}>
                        <Arrow
                            className={cn(
                                'career-screen-slider__nav-el career-screen-slider__nav-el_prev'
                            )}
                        />
                        <Arrow
                            className={cn(
                                'career-screen-slider__nav-el career-screen-slider__nav-el_next'
                            )}
                        />
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default CareerScreenSldier;

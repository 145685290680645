import React from 'react';

import CareerNumber from '../CareerNumber';

import './index.css';


import { useClassnames } from '@/hooks/use-classnames';
import { TNumberBlock } from '@/types/strapi/career';

interface IProps {
    data: Array<TNumberBlock>
}

const CareerNumbers: React.FC<IProps> = ({ data }) => {
    const cn = useClassnames();

    return (
        <div className={cn('career-numbers')}>
            {data.map((el, index) => (
                <CareerNumber key={index} title={el.title} text={el.text} />
            ))}
        </div>
    );
};

export default CareerNumbers;

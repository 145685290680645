import React from 'react';

import './index.css';

import { TrackingWrapper } from '@/components/AdRiver/TrackingWrapper';
import LinkButton from '@/components/button-like/link-button';
import Text from '@/components/text';

import CareerDirectionCard from '../CareerDirectionCard';


import { E_TRACKING_EVENTS } from '@/types/adriver/e-tracking-events';
import { IDirection, IVacancy } from '@/types/strapi/vacancies';
import { triggerAdRiverEvent } from '@/utils/adriver';
import { reachGoal } from '@/utils/reachGoal';


interface IProps {
    directions: Array<IDirection>,
    vacancies: Array<IVacancy>
}

const CareerDirections: React.FC<IProps> = ({ directions, vacancies }) => {
    return (
        <section className="career-directions">
            <div className="career-directions__wrapper">
                <div className="career-directions__header">
                    <h3
                        className="career-directions__heading"
                    >
                        Выберите направление, <br />которое вам интересно
                    </h3>
                    <Text className="career-directions__text" size={3}>
                        Сегодня <span className="selection-pattern">Navio</span>&nbsp;&mdash; это 800+ высококлассных специалистов, обладающих самыми разными компетенциями.
                        Мы&nbsp;активно растём и&nbsp;ждём в&nbsp;команду тех, кто, как и&nbsp;мы, стремится изменить индустрию
                        транспорта и&nbsp;сделать комфортнее жизнь миллионов людей.
                    </Text>
                </div>
                <div className="career-directions__grid">
                    {directions.map((el, index) => <CareerDirectionCard key={index} vacancies={vacancies} direction={el} />)}
                </div>
                <TrackingWrapper>
                    <LinkButton
                        href="/vacancies"
                        isGatsbyLink={true}
                        className="career-directions__button"
                        isBlock={false}
                        onClick={() => {
                            void triggerAdRiverEvent({ trackingEvent: E_TRACKING_EVENTS.ALL_VACANCIES });
                            reachGoal('career_button_vacancies_all_click');
                        }}
                        size="s"
                    >
                        Все вакансии
                    </LinkButton>
                </TrackingWrapper>
            </div>
        </section>
    );
};

export default CareerDirections;

import { Link } from 'gatsby';
import React, { useMemo } from 'react';

import './index.css';

import Tag from '@/components/tag';
import Text from '@/components/text';
import { useClassnames } from '@/hooks/use-classnames';
import Arrow from '@/images/document-hover-arrow.inline.svg';
import Substrate from '@/images/document-substrate.inline.svg';
import { IDirection, ITag, IVacancy } from '@/types/strapi/vacancies';


interface IProps {
    direction: IDirection,
    vacancies: Array<IVacancy>
}

const CareerDirectionCard: React.FC<IProps> = ({ direction, vacancies }) => {
    const cn = useClassnames();

    const directionTags: Array<ITag> = useMemo(() => {
        const directionVacancies: Array<IVacancy> = [];

        vacancies.forEach((el) => {
            if(el.direction?.id === direction.id) {
                directionVacancies.push(el);
            }
        });

        const tagsDirection = directionVacancies.map((el) => el.tags).flat() as Array<ITag>;

        return [...tagsDirection].reduce((a: Array<ITag>, c: ITag) => (a.map((e) => e.id).includes(c.id) || a.push(c), a), []);
    }, []);

    return (
        <Link to={`/vacancies?direction=${direction.id}`} className={cn('career-direction-card')}>
            <div className={cn('career-direction-card__wrapper')}>
                <div className="career-direction-card__header">
                    <Text className={cn('career-direction-card__title')} size={2} dangerouslySetInnerHTML={{ __html: direction.header }} />
                    <p className={cn('career-direction-card__description')} dangerouslySetInnerHTML={{ __html: direction.description }} />
                </div>
                {directionTags.length > 0 && (
                    <div className={cn('career-direction-card__tag-wrapper')}>
                        {directionTags.map((el, index) => (
                            <Link key={index} to={`/vacancies?tags=${el.id}`}>
                                <Tag text={el.text} />
                            </Link>
                        ))}
                    </div>
                )}
            </div>
            {/* <Arrow className="career-direction-card__arrow" />*/}
            <Substrate className="career-direction-card__substrate" />
        </Link>
    );
};

export default CareerDirectionCard;

import React from 'react';

import './index.css';

import Text from '@/components/text';
import { useClassnames } from '@/hooks/use-classnames';
import { TTasksBlock } from '@/types/strapi/career';

interface IProps {
    title: string,
    data: Array<TTasksBlock>
}

const CareerTasks = ({ title, data }: IProps) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-tasks')}>
            <div className={cn('career-tasks__wrapper')}>
                <h3
                    className={cn('career-worth__heading')}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className={cn('career-tasks__grid')}>
                    {data.map((el, index) => {
                        return (
                            <div key={index} className={cn('career-tasks__card')}>
                                <div className={cn('career-tasks__card-content')}>
                                    <Text
                                        className={cn('career-tasks__card-text')}
                                        size={3}
                                        dangerouslySetInnerHTML={{ __html: el.text }}
                                    />
                                    <div className={cn('career-tasks__card-number')}>{index + 1}</div>
                                </div>
                                <img className={cn('career-tasks__card-image')} src={el.image} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default CareerTasks;

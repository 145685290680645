import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';

import './index.css';

import { ITitleAndText } from '../../../../types/strapi/career';

import CareerFeature from '@/components/CareerPage/components/CareerFeature';

interface IProps {
    data: Array<ITitleAndText>
}

const CareerFeatures: React.FC<IProps> = ({ data }) => {
    const cn = useClassnames();

    return (
        <div className={cn('career-features')}>
            {data.map((el, index) => (
                <CareerFeature key={index} title={el.title} text={el.text} />
            ))}
        </div>
    );
};

export default CareerFeatures;

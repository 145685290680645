import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';

import './index.css';

import { TFilterType } from '@/components/VacanciesPage/types';

interface IProps {
    text: string,
    isActive?: boolean,
    onClickTag?: (e: React.MouseEvent<HTMLLIElement>) => void,
    strapiId?: number | string,
    showCrosshair?: boolean,
    filterType?: TFilterType
}

const Tag = ({ text, strapiId, isActive, onClickTag, showCrosshair = false, filterType }: IProps) => {
    const cn = useClassnames();

    return (
        <li
            className={cn('tag tag_light',
                {
                    'tag_light_active': isActive
                }
            )}
            onClick={onClickTag}
            data-id={strapiId}
            data-filter-type={filterType}
        >{text}
            {showCrosshair && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M4.66699 4.66797L8.00033 8.0013M8.00033 8.0013L11.3337 4.66797M8.00033 8.0013L4.66699 11.3346M8.00033 8.0013L11.3337 11.3346"
                        stroke="#1F272E"
                    />
                </svg>
            )}
        </li>
    );
};

export default Tag;

import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './index.css';

import LinkButton from '@/components/button-like/link-button';
import { useClassnames } from '@/hooks/use-classnames';
import Arrow from '@/images/slider-arrow.inline.svg';

interface IProps {
    data: Array<{
        image: string,
        title: string,
        text: string
    }>,
    title?: string,
    subtitle?: string,
    hiddenButtons?: boolean,
    hiddenGradient?: boolean,
    keyI: number
}

const CareerWork = ({ data, subtitle, title, hiddenButtons = false, hiddenGradient = false, keyI }: IProps) => {
    const cn = useClassnames();

    const swiperSetting = {
        slidesPerView : 3,
        slidesPerGroup: 3,
        loop          : true,
        spaceBetween  : 20,
        navigation    : {
            prevEl: `.career-work__nav-el_prev_${keyI}`,
            nextEl: `.career-work__nav-el_next_${keyI}`
        }
    };

    return (
        <section className={cn('career-work')}>
            <div className={cn('career-work__wrapper')}>
                <div className={cn('career-work__header')}>
                    <h3 className={cn('career-work__heading')}>
                        {title}
                    </h3>
                </div>

                {subtitle && <p className="career-work__subtitle">{subtitle}</p>}

                <div className={cn('career-work__list')}>
                    {data.map((el, index) => (
                        <div className="career-work__list-item" key={index}>
                            <div className="career-work__list-item-img">
                                <img src={el.image} alt="" />
                            </div>
                            <p className="career-work__list-item-text" dangerouslySetInnerHTML={{ __html: el.title }} />
                        </div>
                    ))}
                </div>

                {/* <Swiper */}
                {/*     key={keyI} */}
                {/*     className={cn(`career-slider, career-slider_${keyI}`)} */}
                {/*     modules={[Navigation]} */}
                {/*     {...swiperSetting} */}
                {/* > */}
                {/*     {data.concat(data).map((el, index) => ( */}
                {/*         <SwiperSlide */}
                {/*             key={index} */}
                {/*             className={cn('career-slider__slide')} */}
                {/*         > */}
                {/*             <img */}
                {/*                 className={cn('career-work__slide-img')} */}
                {/*                 src={el.image} */}
                {/*             /> */}
                {/*             {el.title && <Heading */}
                {/*                 className={cn('career-work__slide-title')} */}
                {/*                 level={5} */}
                {/*                 as="p" */}
                {/*                 dangerouslySetInnerHTML={{ __html: el.title }} */}
                {/*             />} */}
                {/*             { el.text && <Text */}
                {/*                 className={cn('career-work__slide-text')} */}
                {/*                 size={4} */}
                {/*                 dangerouslySetInnerHTML={{ __html: el.text }} */}
                {/*             />} */}
                {/*         </SwiperSlide> */}
                {/*     ))} */}
                {/* </Swiper> */}

                {/* {!hiddenButtons && ( */}
                {/*     <LinkButton */}
                {/*         className={cn('career-work__button')} */}
                {/*         size="s" */}
                {/*         isGatsbyLink={true} */}
                {/*         href="/vacancies" */}
                {/*     > */}
                {/*         Смотреть вакансии */}
                {/*     </LinkButton> */}
                {/* )} */}
            </div>
        </section>
    );
};

export default CareerWork;

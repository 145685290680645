import React from 'react';

import ResumeButtonWrapper from '../../../vacancy/components/button-wrapper-resume';

import './index.css';

import { TrackingWrapper } from '@/components/AdRiver/TrackingWrapper';
import LinkButton from '@/components/button-like/link-button';
import { Picture } from '@/components/Picture';
import { useClassnames } from '@/hooks/use-classnames';
import { E_TRACKING_EVENTS } from '@/types/adriver/e-tracking-events';
import { reachGoal } from '@/utils/reachGoal';

interface IButtonsProps {
    classNames?: string,
    buttonText?: string
}

interface IProps {
    title: string,
    buttonText?: string
}

const imageKv = {
    desktopNormal: '/career/banner.png',
    tablet       : '/career/banner.png',
    mobile       : '/career/banner.png'
};

const CareerBannerButtons: React.FC<IProps> = ({ classNames, buttonText }: IButtonsProps) => {
    const cn = useClassnames();

    return (
        <div className={cn('career-banner__buttons', classNames)}>
            <TrackingWrapper trackingEvent={E_TRACKING_EVENTS.OUR_VACANCIES}>
                <LinkButton
                    onClick={() => reachGoal('career_button_vacancies_click')}
                    href="/vacancies"
                    isGatsbyLink={true}
                    className="career-banner__button"
                    isBlock={false}
                    size="s"
                >
                    {buttonText}
                </LinkButton>
            </TrackingWrapper>
            <TrackingWrapper trackingEvent={E_TRACKING_EVENTS.SEND_RESUME_II}>
                <ResumeButtonWrapper
                    onClick={() => reachGoal('career_button_resume_click')}
                    className="career-banner__button"
                    variant="transparent"
                >
                    Хочу отправить резюме
                </ResumeButtonWrapper>
            </TrackingWrapper>
        </div>
    );
};

const CareerBanner: React.FC<IProps> = ({
    title,
    buttonText
}) => {
    const cn = useClassnames();

    return (
        <section className={cn('career-banner')}>
            <div className={cn('career-banner__content')}>
                <h1 className={cn('career-banner__title')} dangerouslySetInnerHTML={{ __html: title }} />
                <CareerBannerButtons
                    buttonText={buttonText}
                />
            </div>
            <Picture
                className={cn('career-banner__kv')}
                image={imageKv}
                alt="banner-image"
            />
            <CareerBannerButtons
                classNames="career-banner__buttons_mobile"
                buttonText={buttonText}
            />
        </section>
    );
};

export default CareerBanner;
